.container-payment-methods .payment-item {
  background-color: var(--light-grey);
    color: var(--dark-grey);
    width: 100%;
    border-radius: 5px;
    padding: 2px 20px;
    margin-bottom: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
}
.container-payment-methods .payment-item:hover {
  background-color: #d3d3d3;
  transition: .1s ease-in;
}

.container-payment-methods .payment-item.active {
  background-color: var(--light-green);
  color: white;
  transition: .1s ease-in
}


.container-payment-methods .payment-title {
  font-family: var(--font-bebas);
  font-size: 1.5rem;
}

.container-payment-methods .payment-item .contain-circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid var(--grey);
  display: flex;
  justify-content: center;
  align-items: center;
}

.container-payment-methods .payment-item.active .contain-circle {
    border: 1px solid var(--light-grey);
}

.container-payment-methods .circle {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: var(--light-grey);
}


.container-payment-methods .container-text {
  display: flex;
  flex-direction: column;
}

@media (min-width: 768px) {
  .container-payment-methods .container-text {
    flex-direction: row;
    align-items: center;
    gap: 3px;
  }
}

@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/brands';
@import '~bootstrap';

// Color scheme
$default:       #5bc23b;
$primary:       #5bc23b;
$secondary:     #176301;

@import 'main.scss';
@import 'animate.css';
@import 'glightbox.min.css';
@import 'tiny-slider.css';

.obscure {
  filter: brightness(0.7);
}

/*======================================
    Variables
========================================*/
/*======================================
    Normalize CSS
========================================*/
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Inter", sans-serif;
  font-weight: normal;
  font-style: normal;
  color: #888;
  overflow-x: hidden;
  font-size: 15px;
}

p {
  margin: 0;
  padding: 0;
  font-size: 15px;
  line-height: 24px;
}

* {
  margin: 0;
  padding: 0;
}

.navbar-toggler:focus,
a:focus,
input:focus,
select:focus,
textarea:focus,
button:focus,
.btn:focus,
.btn.focus,
.btn:not(:disabled):not(.disabled).active,
.btn:not(:disabled):not(.disabled):active {
  text-decoration: none;
  outline: none;
  outline: none !important;
  border-color: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.form-check-input:checked {
  background-color: $primary;
  border-color: $primary;
}

select {
  -webkit-writing-mode: horizontal-tb !important;
  text-rendering: auto;
  color: #081828;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  display: inline-block;
  text-align: start;
  -webkit-appearance: menulist;
  -moz-appearance: menulist;
  appearance: menulist;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  white-space: pre;
  -webkit-rtl-ordering: logical;
  background-color: -internal-light-dark(white, #3b3b3b);
  cursor: default;
  margin: 0em;
  font: 400 14px;
  border-radius: 0px;
  border-width: 1px;
  border-style: solid;
  border-color: -internal-light-dark(#767676, #858585);
  -o-border-image: initial;
  border-image: initial;

}

li,
a {
  display: inline-block;
  text-decoration: none !important;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

img {
  max-width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: inherit;
}

h1 {
  font-size: 50px;
}

h2 {
  font-size: 40px;
}

h3 {
  font-size: 30px;
}

h4 {
  font-size: 25px;
}

h5 {
  font-size: 20px;
}

h6 {
  font-size: 16px;
}

ul,
ol {
  margin: 0px;
  padding: 0px;
  list-style-type: none;
}

.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-35 {
  margin-top: 35px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-45 {
  margin-top: 45px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-55 {
  margin-top: 55px;
}

.mt-60 {
  margin-top: 60px;
}

.mt-65 {
  margin-top: 65px;
}

.mt-70 {
  margin-top: 70px;
}

.mt-75 {
  margin-top: 75px;
}

.mt-80 {
  margin-top: 80px;
}

.mt-85 {
  margin-top: 85px;
}

.mt-90 {
  margin-top: 90px;
}

.mt-95 {
  margin-top: 95px;
}

.mt-100 {
  margin-top: 100px;
}

.mt-105 {
  margin-top: 105px;
}

.mt-110 {
  margin-top: 110px;
}

.mt-115 {
  margin-top: 115px;
}

.mt-120 {
  margin-top: 120px;
}

.mt-125 {
  margin-top: 125px;
}

.mt-130 {
  margin-top: 130px;
}

.mt-135 {
  margin-top: 135px;
}

.mt-140 {
  margin-top: 140px;
}

.mt-145 {
  margin-top: 145px;
}

.mt-150 {
  margin-top: 150px;
}

.mt-155 {
  margin-top: 155px;
}

.mt-160 {
  margin-top: 160px;
}

.mt-165 {
  margin-top: 165px;
}

.mt-170 {
  margin-top: 170px;
}

.mt-175 {
  margin-top: 175px;
}

.mt-180 {
  margin-top: 180px;
}

.mt-185 {
  margin-top: 185px;
}

.mt-190 {
  margin-top: 190px;
}

.mt-195 {
  margin-top: 195px;
}

.mt-200 {
  margin-top: 200px;
}

.mt-205 {
  margin-top: 205px;
}

.mt-210 {
  margin-top: 210px;
}

.mt-215 {
  margin-top: 215px;
}

.mt-220 {
  margin-top: 220px;
}

.mt-225 {
  margin-top: 225px;
}

.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-35 {
  margin-bottom: 35px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-45 {
  margin-bottom: 45px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-55 {
  margin-bottom: 55px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mb-65 {
  margin-bottom: 65px;
}

.mb-70 {
  margin-bottom: 70px;
}

.mb-75 {
  margin-bottom: 75px;
}

.mb-80 {
  margin-bottom: 80px;
}

.mb-85 {
  margin-bottom: 85px;
}

.mb-90 {
  margin-bottom: 90px;
}

.mb-95 {
  margin-bottom: 95px;
}

.mb-100 {
  margin-bottom: 100px;
}

.mb-105 {
  margin-bottom: 105px;
}

.mb-110 {
  margin-bottom: 110px;
}

.mb-115 {
  margin-bottom: 115px;
}

.mb-120 {
  margin-bottom: 120px;
}

.mb-125 {
  margin-bottom: 125px;
}

.mb-130 {
  margin-bottom: 130px;
}

.mb-135 {
  margin-bottom: 135px;
}

.mb-140 {
  margin-bottom: 140px;
}

.mb-145 {
  margin-bottom: 145px;
}

.mb-150 {
  margin-bottom: 150px;
}

.mb-155 {
  margin-bottom: 155px;
}

.mb-160 {
  margin-bottom: 160px;
}

.mb-165 {
  margin-bottom: 165px;
}

.mb-170 {
  margin-bottom: 170px;
}

.mb-175 {
  margin-bottom: 175px;
}

.mb-180 {
  margin-bottom: 180px;
}

.mb-185 {
  margin-bottom: 185px;
}

.mb-190 {
  margin-bottom: 190px;
}

.mb-195 {
  margin-bottom: 195px;
}

.mb-200 {
  margin-bottom: 200px;
}

.mb-205 {
  margin-bottom: 205px;
}

.mb-210 {
  margin-bottom: 210px;
}

.mb-215 {
  margin-bottom: 215px;
}

.mb-220 {
  margin-bottom: 220px;
}

.mb-225 {
  margin-bottom: 225px;
}

.pt-5 {
  padding-top: 5px;
}

.pt-10 {
  padding-top: 10px;
}

.pt-15 {
  padding-top: 15px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-25 {
  padding-top: 25px;
}

.pt-30 {
  padding-top: 30px;
}

.pt-35 {
  padding-top: 35px;
}

.pt-40 {
  padding-top: 40px;
}

.pt-45 {
  padding-top: 45px;
}

.pt-50 {
  padding-top: 50px;
}

.pt-55 {
  padding-top: 55px;
}

.pt-60 {
  padding-top: 60px;
}

.pt-65 {
  padding-top: 65px;
}

.pt-70 {
  padding-top: 70px;
}

.pt-75 {
  padding-top: 75px;
}

.pt-80 {
  padding-top: 80px;
}

.pt-85 {
  padding-top: 85px;
}

.pt-90 {
  padding-top: 90px;
}

.pt-95 {
  padding-top: 95px;
}

.pt-100 {
  padding-top: 100px;
}

.pt-105 {
  padding-top: 105px;
}

.pt-110 {
  padding-top: 110px;
}

.pt-115 {
  padding-top: 115px;
}

.pt-120 {
  padding-top: 120px;
}

.pt-125 {
  padding-top: 125px;
}

.pt-130 {
  padding-top: 130px;
}

.pt-135 {
  padding-top: 135px;
}

.pt-140 {
  padding-top: 140px;
}

.pt-145 {
  padding-top: 145px;
}

.pt-150 {
  padding-top: 150px;
}

.pt-155 {
  padding-top: 155px;
}

.pt-160 {
  padding-top: 160px;
}

.pt-165 {
  padding-top: 165px;
}

.pt-170 {
  padding-top: 170px;
}

.pt-175 {
  padding-top: 175px;
}

.pt-180 {
  padding-top: 180px;
}

.pt-185 {
  padding-top: 185px;
}

.pt-190 {
  padding-top: 190px;
}

.pt-195 {
  padding-top: 195px;
}

.pt-200 {
  padding-top: 200px;
}

.pt-205 {
  padding-top: 205px;
}

.pt-210 {
  padding-top: 210px;
}

.pt-215 {
  padding-top: 215px;
}

.pt-220 {
  padding-top: 220px;
}

.pt-225 {
  padding-top: 225px;
}

.pb-5 {
  padding-bottom: 5px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-25 {
  padding-bottom: 25px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pb-35 {
  padding-bottom: 35px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pb-45 {
  padding-bottom: 45px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pb-55 {
  padding-bottom: 55px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pb-65 {
  padding-bottom: 65px;
}

.pb-70 {
  padding-bottom: 70px;
}

.pb-75 {
  padding-bottom: 75px;
}

.pb-80 {
  padding-bottom: 80px;
}

.pb-85 {
  padding-bottom: 85px;
}

.pb-90 {
  padding-bottom: 90px;
}

.pb-95 {
  padding-bottom: 95px;
}

.pb-100 {
  padding-bottom: 100px;
}

.pb-105 {
  padding-bottom: 105px;
}

.pb-110 {
  padding-bottom: 110px;
}

.pb-115 {
  padding-bottom: 115px;
}

.pb-120 {
  padding-bottom: 120px;
}

.pb-125 {
  padding-bottom: 125px;
}

.pb-130 {
  padding-bottom: 130px;
}

.pb-135 {
  padding-bottom: 135px;
}

.pb-140 {
  padding-bottom: 140px;
}

.pb-145 {
  padding-bottom: 145px;
}

.pb-150 {
  padding-bottom: 150px;
}

.pb-155 {
  padding-bottom: 155px;
}

.pb-160 {
  padding-bottom: 160px;
}

.pb-165 {
  padding-bottom: 165px;
}

.pb-170 {
  padding-bottom: 170px;
}

.pb-175 {
  padding-bottom: 175px;
}

.pb-180 {
  padding-bottom: 180px;
}

.pb-185 {
  padding-bottom: 185px;
}

.pb-190 {
  padding-bottom: 190px;
}

.pb-195 {
  padding-bottom: 195px;
}

.pb-200 {
  padding-bottom: 200px;
}

.pb-205 {
  padding-bottom: 205px;
}

.pb-210 {
  padding-bottom: 210px;
}

.pb-215 {
  padding-bottom: 215px;
}

.pb-220 {
  padding-bottom: 220px;
}

.pb-225 {
  padding-bottom: 225px;
}

.img-bg {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .container {
    width: 450px;
  }
}

/* Bread Crumbs */
.breadcrumbs {
  position: relative;
  padding-bottom: 100px;
  padding-top: 180px;
  z-index: 2;
  text-align: left;
  background-color: $primary;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumbs {
    padding-bottom: 60px;
    padding-top: 120px;
  }
}

@media (max-width: 767px) {
  .breadcrumbs {
    padding-bottom: 60px;
    padding-top: 120px;
  }
}

.breadcrumbs .breadcrumbs-content {
  position: relative;
  text-align: center;
}

.breadcrumbs .breadcrumbs-content .page-title {
  font-size: 32px;
  color: #fff;
  font-weight: 700;
  position: relative;
  line-height: 50px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumbs .breadcrumbs-content .page-title {
    font-size: 28px;
    line-height: 42px;
  }
}

@media (max-width: 767px) {
  .breadcrumbs .breadcrumbs-content .page-title {
    font-size: 25px;
    line-height: 38px;
  }
}

.breadcrumbs .breadcrumbs-content .breadcrumb-nav {
  background: transparent;
  border-radius: 0;
  margin-bottom: 0;
  padding: 0;
  display: inline-block;
}

.breadcrumbs .breadcrumb-nav {
  text-align: center;
  margin-top: 15px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumbs .breadcrumb-nav {
    margin-top: 10px;
  }
}

@media (max-width: 767px) {
  .breadcrumbs .breadcrumb-nav {
    margin-top: 8px;
  }
}

.breadcrumbs .breadcrumb-nav li {
  display: inline-block;
  position: relative;
  padding-right: 15px;
  margin-right: 15px;
  text-transform: capitalize;
}

.breadcrumbs .breadcrumb-nav li:after {
  content: "\ea5c";
  font-family: lineIcons;
  font-size: 10px;
  position: absolute;
  top: 4px;
  right: -7px;
}

.breadcrumbs .breadcrumb-nav li:last-child {
  margin: 0;
  padding: 0;
}

.breadcrumbs .breadcrumb-nav li:last-child::after {
  display: none;
}

.breadcrumbs .breadcrumb-nav li,
.breadcrumbs .breadcrumb-nav li a {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
}

.breadcrumbs .breadcrumb-nav li a {
  position: relative;
}

.breadcrumbs .breadcrumb-nav li a:hover {
  text-decoration: underline;
}

.section {
  padding-top: 100px;
  padding-bottom: 100px;
  position: relative;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

@media (max-width: 767px) {
  .section {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

/* Section Title */
.section-title {
  text-align: center;
  margin-bottom: 80px;
  padding: 0 300px;
  position: relative;
  z-index: 5;
}

.section-title h3 {
  font-size: 15px;
  font-weight: 600;
  display: block;
  margin-bottom: 8px;
  color: $primary;
  text-transform: uppercase;
}

.section-title h2 {
  font-size: 34px;
  margin-bottom: 25px;
  line-height: 42px;
  text-transform: capitalize;
  position: relative;
  font-weight: 800;
}

.section-title p {
  font-size: 15px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-title {
    padding: 0px 200px;
    margin-bottom: 70px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title {
    padding: 0px 20px;
    margin-bottom: 70px;
  }

  .section-title h3 {
    font-size: 14px;
  }

  .section-title h2 {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 20px;
  }

  .section-title p {
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  .section-title {
    padding: 0px 10px;
    margin-bottom: 60px;
  }

  .section-title h3 {
    font-size: 14px;
  }

  .section-title h2 {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 18px;
  }

  .section-title p {
    font-size: 14px;
  }
}

.section-title.align-right {
  padding: 0;
  padding-left: 600px;
}

.section-title.align-right h2:before {
  display: none;
}

.section-title.align-right h2:after {
  position: absolute;
  right: 0;
  bottom: -1px;
  height: 2px;
  width: 50px;
  background: $primary;
  content: "";
}

.section-title.align-left {
  padding: 0;
  padding-right: 600px;
}

.section-title.align-left h2:before {
  left: 0;
  margin-left: 0;
}

/* One Click Scrool Top Button*/
.scroll-top {
  width: 45px;
  height: 45px;
  line-height: 45px;
  background: $primary;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 14px;
  color: #fff !important;
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 9;
  cursor: pointer;
  -webkit-transition: all .3s ease-out 0s;
  transition: all .3s ease-out 0s;
  border-radius: 5px;
}

.scroll-top:hover {
  -webkit-box-shadow: 0 1rem 3rem rgba(35, 38, 45, 0.15) !important;
  box-shadow: 0 1rem 3rem rgba(35, 38, 45, 0.15) !important;
  -webkit-transform: translate3d(0, -5px, 0);
  transform: translate3d(0, -5px, 0);
  background-color: #081828;
}

/* Overlay */
.overlay {
  position: relative;
  z-index: 1;
}

.overlay::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  z-index: -1;
}

/* Pagination CSS */
.pagination {
  text-align: left;
  margin: 40px 0 0 0;
  display: block;
}

.pagination.center {
  text-align: center;
}

.pagination.right {
  text-align: right;
}

.pagination.left {
  text-align: left;
}

.pagination .pagination-list {
  display: inline-block;
  overflow: hidden;
}

.pagination .pagination-list li {
  margin-right: 5px;
  display: inline-block;
  margin-top: 10px;
}

.pagination .pagination-list li:last-child {
  margin-right: 0px;
}

.pagination .pagination-list li a {
  background: #fff;
  color: #081828;
  font-weight: 400;
  font-size: 14px;
  border-radius: 5px;
  padding: 8px 20px;
  text-align: center;
  border: 1px solid #eee;
}

.pagination .pagination-list li.active a,
.pagination .pagination-list li:hover a {
  background: $primary;
  color: #fff;
  border-color: transparent;
}

.pagination .pagination-list li a i {
  font-size: 13px;
}

.blog-grids.pagination {
  margin-top: 50px;
  text-align: center;
}

.button .btn {
  display: inline-block;
  text-transform: capitalize;
  font-size: 15px;
  font-weight: 600;
  padding: 13px 30px;
  background-color: $primary;
  color: #fff;
  border: none;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  border-radius: 5px;
  position: relative;
  z-index: 1;
  margin-right: 7px;
  overflow: hidden;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .button .btn {
    padding: 12px 25px;
    font-size: 14px;
    font-weight: 500;
  }
}

@media (max-width: 767px) {
  .button .btn {
    padding: 12px 25px;
    font-size: 14px;
    font-weight: 500;
  }
}

.button .btn i {
  display: inline-block;
  margin-right: 5px;
}

.button .btn:last-child {
  margin: 0;
}

.button .btn:hover {
  color: #fff;
  background-color: #5A29C8;
  -webkit-box-shadow: 0px 4px 4px #0000000f;
  box-shadow: 0px 4px 4px #0000000f;
}

.button .btn-alt {
  background-color: #081828 !important;
  color: #fff !important;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .button .btn-alt {
    padding: 10px 30px;
  }
}

@media (max-width: 767px) {
  .button .btn-alt {
    padding: 10px 30px;
  }
}

.button .btn-alt:hover {
  background-color: $primary !important;
  color: #fff !important;
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.align-center {
  text-align: center;
}

/* Preloader */
.preloader {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999999;
  width: 100%;
  height: 100%;
  background-color: #fff;
  overflow: hidden;
}

.preloader-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.preloader-icon {
  width: 100px;
  height: 100px;
  display: inline-block;
  padding: 0px;
}

.preloader-icon span {
  position: absolute;
  display: inline-block;
  width: 100px;
  height: 100px;
  border-radius: 100%;
  background: $primary;
  -webkit-animation: preloader-fx 1.6s linear infinite;
  animation: preloader-fx 1.6s linear infinite;
}

.preloader-icon span:last-child {
  animation-delay: -0.8s;
  -webkit-animation-delay: -0.8s;
}

@keyframes preloader-fx {
  0% {
    -webkit-transform: scale(0, 0);
    transform: scale(0, 0);
    opacity: 0.5;
  }

  100% {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
    opacity: 0;
  }
}

@-webkit-keyframes preloader-fx {
  0% {
    -webkit-transform: scale(0, 0);
    opacity: 0.5;
  }

  100% {
    -webkit-transform: scale(1, 1);
    opacity: 0;
  }
}

/*======================================
	01. Start Header CSS
========================================*/
.header {
  width: 100%;
  background: transparent;
  // position: absolute;
  width: 100%;
  left: 0;
  top: 0;
}


.logo-item_container {
  height: 100%;
}

.logo-item {
  line-height: 1.3rem;
}

.header .nav-item, .header .nav-item a{
  color: var(--dark-green) !important;
}

.header .nav-item a:hover{
  color: var(--light-green) !important;
}

.header .button .btn.btn-order-now:hover {
  // color: white !important;
  background-color: #01631a !important;
}

.header .btn-discount {
  // color: var(--light-green) !important;
  color: #5bc23b !important;
  border: 1px solid !important;
  background-color: transparent !important;
  text-wrap: nowrap;
  display: none;
}

.header .btn-discount:hover,
.header.sticky .btn-discount:hover{
  color: #fff !important;
  border: none;
  // background-color: $primary !important;
  background-color: #01631a !important;
}



@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header {
    padding: 18px 0 !important;
  }

  .header .mobile-menu-btn .toggler-icon {
    // background-color: #fff;
    // background-color: var(--dark-green) !important;
  }

  .header .button {
    margin: 0 !important;
  }

  .header .navbar-collapse {
    position: absolute;
    top: 67px !important;
    left: 0;
    width: 100%;
    background-color: #fff;
    z-index: 9;
    -webkit-box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.1);
    padding: 10px 20px;
    max-height: 350px;
    overflow-y: scroll;
    border-top: 1px solid #eee;
    border-radius: 6px;
  }

  .header .navbar .navbar-nav .nav-item a:hover {
    color: $primary !important;
  }

  .header .navbar .navbar-nav .nav-item a.active {
    color: $primary !important;
  }

  .header .navbar-nav .nav-item {
    margin: 0;
  }

  .header .navbar-nav .nav-item:hover a {
    color: $primary;
  }

  .header .navbar-nav .nav-item a {
    padding: 12px 16px !important;
  }

  .header .navbar-nav .nav-item a::before {
    display: none;
  }

  .header .navbar-nav .nav-item .sub-menu {
    position: static;
    width: 100%;
    opacity: 1;
    visibility: visible;
    -webkit-box-shadow: none;
    box-shadow: none;
    padding: 0;
    border: none;
    margin-left: 15px;
    margin-right: 15px;
  }

  .header .navbar-nav .nav-item .sub-menu .nav-item a {
    padding: 12px 12px;
  }

  .header .navbar-nav .nav-item .sub-menu .nav-item a:hover {
    color: $primary !important;
  }

  .header .navbar-nav .nav-item a {
    color: #051441;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 10px 0;
  }

  .header .navbar-nav .nav-item a::after {
    opacity: 1;
    visibility: visible;
  }

  .header .navbar-nav .nav-item .sub-menu li.active {
    background: #fff !important;
    color: $primary !important;
  }

  .header .navbar-nav .nav-item .sub-menu .nav-item {
    margin: 0 !important;
  }

  .header .navbar-nav .nav-item .sub-menu .nav-item a {
    padding: 10px 12px !important;
  }

  .header .navbar-nav .nav-item .sub-menu li:hover {
    background: #fff !important;
    color: $primary !important;
  }

  .header .navbar-nav .nav-item a {
    font-size: 14px;
  }

  .header .navbar-nav .nav-item a:hover {
    color: $primary;
  }
}

@media (max-width: 767px) {
  .header {
    padding: 1px 0 !important;
  }

  .header .mobile-menu-btn .toggler-icon {
    // background-color: var(--dark-green) !important;
  }

  .header .navbar-collapse {
    position: absolute;
    top: 80px !important;
    left: 0;
    width: 100%;
    background-color: #fff;
    z-index: 9;
    -webkit-box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.1);
    padding: 30px 40px;
    max-height: 350px;
    overflow-y: scroll;
    border-top: 1px solid #eee;
    border-radius: 6px;
  }

  .header .navbar .navbar-nav .nav-item a:hover {
    color: $primary !important;
  }

  .header .navbar .navbar-nav .nav-item a.active {
    color: $primary !important;
  }

  .header .navbar-nav .nav-item {
    margin: 0;
  }

  .header .navbar-nav .nav-item #menu-li {
    margin: 0 0 1.5rem;
  }

  .header .navbar-nav .nav-item:hover a {
    color: $primary;
  }

  /*  .header .navbar-nav .nav-item a {
    padding: 12px 16px !important;
  } */
  .header .navbar-nav .nav-item a::before {
    display: none;
  }

  .header .navbar-nav .nav-item .sub-menu {
    position: static;
    width: 100%;
    opacity: 1;
    visibility: visible;
    -webkit-box-shadow: none;
    box-shadow: none;
    padding: 0;
    border: none;
    margin-left: 15px;
    margin-right: 15px;
  }

  .header .navbar-nav .nav-item .sub-menu .nav-item a {
    padding: 12px 12px;
  }

  .header .navbar-nav .nav-item .sub-menu .nav-item a:hover {
    color: $primary !important;
  }

  .header .navbar-nav .nav-item a {
    color: white;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 10px 0;
  }

  .header .navbar-nav .nav-item a::after {
    opacity: 1;
    visibility: visible;
  }

  .header .navbar-nav .nav-item .sub-menu li.active {
    background: #fff !important;
    color: $primary !important;
  }

  .header .navbar-nav .nav-item .sub-menu .nav-item {
    margin: 0 !important;
  }

  .header .navbar-nav .nav-item .sub-menu .nav-item a {
    padding: 10px 12px !important;
  }

  .header .navbar-nav .nav-item .sub-menu li:hover {
    background: #fff !important;
    color: $primary !important;
  }

  .header .navbar-nav .nav-item a {
    font-size: 1.1rem;
  }

  .header .navbar-nav .nav-item a:hover {
    color: $primary;
  }
}

@media (max-width: 767px) {
  .header .button {
    display: none;
  }
}

.header .button .btn {
// background-color: transparent;
  background-color: #5bc23b;
  border: 2px solid #fff;
  color: #fff;
  font-weight: 500;
  padding: 12px 30px;
  padding: .3rem 1.1rem;
  font-size: 1.2rem;
  font-family: var(--font-bebas);
  text-wrap: nowrap;
}

.header .navbar-brand img {
  width: 170px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header .navbar-brand img {
    width: 160px;
  }
}

@media (max-width: 767px) {
  .header .navbar-brand img {
    width: 150px;
  }
}

.header.sticky .navbar-brand .white-logo {
  opacity: 0;
  visibility: hidden;
}

.header.sticky .navbar-brand .dark-logo {
  opacity: 1;
  visibility: visible;
}

.header.sticky .button .btn {
  background-color: $primary;
  color: #fff;
  border-color: transparent;
}

.header.sticky .button .btn:hover {
  background-color: #01631a;
  color: #fff !important;
  // background: transparent;
}

.header.sticky .button .btn.btn-discount:hover {
  background-color: #5bc23b;
  color: #fff !important;
}

@media screen and (min-width: 768px){
  .sticky {
    position: fixed;
    z-index: 99;
    background-color: #fff;
    -webkit-box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.05);
    -webkit-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
    top: 0;
  }

}

.navbar-expand-lg .navbar-nav {
  margin: 0;
  margin-left: auto !important;
  margin-right: auto !important;
}

.header .navbar .navbar-nav .nav-item a.active {
  color: #fff;
}

.sticky .navbar .navbar-nav .nav-item a.active {
  color: $primary;
}

.sticky .navbar .navbar-nav .nav-item a {
  color: #7a3429 ;
}

.sticky .navbar .navbar-nav .nav-item a i {
  color: #7a3429 !important;
}

.sticky .navbar .navbar-nav .nav-item span#bebas {
  color: var(--dark-green)
}

.header .navbar .navbar-nav .nav-item a:hover  {
  color: #84EB89;
}

.header .navbar .navbar-nav .nav-item .sub-menu a.active {
  color: #fff;
}

.sticky .navbar .navbar-nav .nav-item .sub-menu a.active {
  color: #fff;
}

.sticky .navbar .mobile-menu-btn .toggler-icon {
  background: #333;
}

/*===== NAVBAR =====*/
.navbar-area {
  width: 100%;
  z-index: 99;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  padding: 0.5rem 0;
}

@media screen and (min-width: 768px) {
  .navbar-area.sticky {
    position: fixed;
    z-index: 99;
    background: $primary;
    -webkit-box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.05);
    -webkit-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
    background: #fff;
    padding: 0px 0;
  }

  .header .btn-discount {
    display: block;
  }

}

.navbar {
  padding: 0;
  position: relative;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.navbar-brand {
  padding-left: 0;
  border-radius: 0;
}

.mobile-menu-btn {
  padding: 0px;
}

.mobile-menu-btn:focus {
  text-decoration: none;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.mobile-menu-btn .toggler-icon {
  width: 30px;
  height: 2px;
  background-color: #222;
  display: block;
  margin: 5px 0;
  position: relative;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.mobile-menu-btn.active .toggler-icon:nth-of-type(1) {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  top: 7px;
}

.mobile-menu-btn.active .toggler-icon:nth-of-type(2) {
  opacity: 0;
}

.mobile-menu-btn.active .toggler-icon:nth-of-type(3) {
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
  top: -7px;
}

.navbar-nav .nav-item {
  z-index: 888;
  position: relative;
  margin-right: 40px;
}

.navbar-nav .nav-item:last-child {
  margin-right: 0 !important;
}

.navbar-nav .nav-item:hover a {
  color: #fff;
}

.sticky .navbar-nav .nav-item:hover a {
  color: $primary;
}

.navbar-nav .nav-item a {
  font-size: 16px;
  color: #fff;
  -webkit-transition: all 0.3s ease-out 0s;
  font-family: var(--font-source);
  transition: all 0.3s ease-out 0s;
  position: relative;
  padding: 35px 0;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  transition: all 0.3s ease-out 0s;
  position: relative;
  text-transform: capitalize;
  font-weight: 600;
}

.navbar-nav .nav-item a#bebas {
  font-family: var(--font-bebas);
  font-weight: normal;
}

.navbar-nav .nav-item a::after {
  opacity: 0;
  visibility: hidden;
}

.navbar-nav .nav-item:hover a:before {
  width: 100%;
}

.navbar-nav .nav-item a.active {
  color: #fff;
}

.navbar-nav .nav-item a.dd-menu::after {
  content: "\ea58";
  font: normal normal normal 1em/1 "LineIcons";
  position: absolute;
  right: 17px;
  font-size: 10px;
  top: 50%;
  margin-left: 5px;
  margin-top: 0px;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  height: 10px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .navbar-nav .nav-item a.dd-menu::after {
    right: 13px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
  .navbar-nav .nav-item a.dd-menu::after {
    top: 18px;
    right: 0;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
  .navbar-nav .nav-item a.collapsed::after {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

.navbar-nav .nav-item:hover>.sub-menu {
  top: 100%;
  opacity: 1;
  visibility: visible;
}

.navbar-nav .nav-item:hover>.sub-menu .sub-menu {
  left: 100%;
  top: 0;
}

.navbar-nav .nav-item .sub-menu {
  padding: 30px;
  min-width: 240px;
  background: #fff;
  -webkit-box-shadow: 0px 5px 20px #0000001a;
  box-shadow: 0px 5px 20px #0000001a;
  position: absolute;
  top: 110% !important;
  left: 0;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  border-radius: 0 0 4px 4px;
  border-radius: 5px;
}

.navbar-nav .nav-item:hover .sub-menu {
  top: 100% !important;
}

.navbar-nav .nav-item .sub-menu .nav-item {
  width: 100%;
  margin-bottom: 15px;
}

.navbar-nav .nav-item .sub-menu .nav-item:last-child {
  margin: 0;
}

.navbar-nav .nav-item .sub-menu .nav-item a {
  padding: 0;
  color: #888;
  display: block;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
  position: relative;
  z-index: 1;
  border-radius: 4px;
  overflow: hidden;
  -webkit-transition: all 0.1s ease;
  transition: all 0.1s ease;
}

.navbar-nav .nav-item .sub-menu .nav-item a:hover {
  color: $primary;
}

.navbar-nav .nav-item .sub-menu.left-menu {
  left: -100%;
}

.navbar-nav .nav-item .sub-menu.collapse:not(.show) {
  display: block;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
  .navbar-nav .nav-item .sub-menu.collapse:not(.show) {
    display: none;
  }
}

.navbar-nav .nav-item .sub-menu>li {
  display: block;
  margin-left: 0;
}

.navbar-nav .nav-item .sub-menu>li:last-child {
  border: none;
}

.navbar-nav .nav-item .sub-menu>li.active>a {
  color: $primary !important;
}

.navbar-nav .nav-item .sub-menu>li>a {
  font-weight: 400;
  display: block;
  padding: 12px 15px;
  font-size: 14px;
  color: #222;
  font-weight: 400;
}

.navbar-nav .nav-item .sub-menu>li:first-child a {
  border: none;
}

.add-list-button {
  display: inline-block;
  margin-left: 10px;
}

.add-list-button .btn i {
  font-size: 14px;
  display: inline-block;
  margin-right: 5px;
}

/*======================================
     End Header CSS
  ========================================*/
/*======================================
    Hero Area CSS
========================================*/
.hero-area {
  position: relative;
  padding: 180px 0 250px 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.variety-desktop {
  display: none !important;
}

@media (min-width: 475px){
  .variety-mobile {
    display: none !important;
  }
  .variety-desktop {
    display: flex !important ;
  }
  
} 

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-area {
    padding: 150px 0 180px 0;
  }
}

@media (max-width: 767px) {
  .hero-area {
    padding: 120px 0 70px 0;
  }
}

.hero-area .hero-content {
  border-radius: 0;
  position: relative;
  overflow: hidden;
  z-index: 1;
  text-align: center;
  padding: 0px 100px;
}

.hero-area .hero-content h5 {
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 20px;
}

.hero-area .hero-content h1 {
  font-size: 48px;
  font-weight: 500;
  line-height: 50px;
  color: #fff;
  text-shadow: 0px 3px 8px #00000017;
  text-transform: capitalize;
}

.hero-area .hero-content h1 span {
  display: block;
}

.hero-area .hero-content p {
  margin-top: 30px;
  font-size: 15px;
  color: #fff;
}

.hero-area .hero-content .button {
  margin-top: 40px;
}

.hero-area .hero-content .button .btn {
  background-color: #fff;
  color: $primary;
  margin-right: 12px;
}

.hero-area .hero-content .button .btn:hover {
  background-color: #081828;
  color: #fff;
}

.hero-area .hero-content .button .btn.btn-alt {
  background-color: #ffffff6b;
  color: #fff;
}

.hero-area .hero-content .button .btn.btn-alt:hover {
  background-color: #fff !important;
  color: $primary !important;
}

h2.code {
  color: var(--light-green);
  font-family: var(--font-source);
  font-weight: 600;
  font-size: 21px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-area .hero-content h1 {
    font-size: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-area .hero-content {
    text-align: center;
  }

  .hero-area .hero-content h1 {
    font-size: 30px;
    font-weight: 700;
    line-height: 38px;
  }

  .hero-area .hero-content p {
    font-size: 15px;
  }
}

@media (max-width: 767px) {
  .hero-area .hero-content {
    padding: 0 10px;
  }

  .hero-area .hero-content h1 {
    font-size: 24px;
    line-height: 32px;
  }

  .hero-area .hero-content p {
    margin-top: 15px;
    font-size: 14px;
    line-height: 22px;
  }

  .hero-area .hero-content .button .btn {
    width: 60%;
    margin: 0;
    margin-bottom: 7px;
  }

  .hero-area .hero-content .button .btn:last-child {
    margin: 0;
  }
}

/*======================================
    Small Features CSS
========================================*/
@font-face {
  font-family: 'Camper Quotes';
  src: url('../../fonts/Camper\ Quotes.ttf');
}
.small-features {
  background-color: #ffd189;
  background-image: url('../../img/guarda-ilus-home.png');
  background-repeat: no-repeat;
  background-position: 50% 96%;
  background-size: contain;
  padding-bottom: 5rem;
}

.small-features .inner-content {
  display: inline-block;
  width: 100%;
  position: relative;
  margin-top: -50%;
  z-index: 5;
}

.single-feature {
  margin-bottom: 1rem;
}

.small-features .single-feature.card__green {
  background-color: #5BC23B;
}

#h2--white {
  margin: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .small-features .inner-content {
    margin-top: -80px;
  }
}

@media (max-width: 767px) {
  .small-features .inner-content {
    margin-top: 0;
  }
}

.small-features .single-feature {
  position: relative;
  padding: 50px 40px;
  display: inline-block;
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 8px;
  padding-left: 100px;
  width: 100%;
  height: 90%;
  -webkit-box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.048);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.048);
  -webkit-transition: all 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55) 0s;
  transition: all 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55) 0s;
}

.small-features .single-feature:hover {
  -webkit-box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.048);
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.048);
  -webkit-transform: translateY(-18px);
  transform: translateY(-18px);
}

.small-features .single-feature i {
  color: $primary;
  font-size: 35px;
  position: absolute;
  left: 40px;
  top: 30%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.single-feature.card__green svg{
  fill: #7A3429;
  width: 35px;
  position: absolute;
  left: 40px;
  top: 30%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.small-features .single-feature h2 {
  font-size: 18px;
  font-weight: 600;
  width: 85%;
  color: #081828;
  font-family: var(--font-source);
}

.small-features .single-feature span {
  font-size: 14px;
  color: #888;
  display: block;
  margin-top: 8px;
  font-weight: 400;
  font-family: var(--font-source);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .small-features .single-feature {
    padding: 40px 30px;
    text-align: center;
  }

  .small-features .single-feature i {
    position: relative;
    left: 0;
    top: 0;
    display: inline-block;
    margin-bottom: 15px;
    -webkit-transform: none;
    transform: none;
  }

  
  .single-feature.card__green svg{
    fill: #7A3429;
    width: 35px;
    position: absolute;
    left: 40px;
    top: 30%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }


  .small-features .single-feature h2 {
    font-size: 15px;
  }
}


@media (min-width: 991px) {
  .small-features {
    background-position: center;
    padding-bottom: 0;
  }

  /* #formBottom {
    background-color: #ffd189;
    background-image: url('../../img/guarda-form.jpg');
    height: 6.8rem;
  } */
}



/* @media screen and (max-width: 475px) {
  .small-features .cards {
    display: none;
  }
} */

/*======================================
    Features Area CSS
========================================*/
.features {
  background-color: #fff;
}

.features .section-title {
  margin-bottom: 30px;
}

.features .single-feature {
  text-align: left;
  padding: 35px;
  background-color: #f9f9f9;
  border-radius: 6px;
  position: relative;
  margin-top: 30px;
  border: 1px solid #eee;
  -webkit-transition: all 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55) 0s;
  transition: all 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55) 0s;
}

.features .single-feature:hover {
  -webkit-box-shadow: 0px 3px 5px #00000017;
  box-shadow: 0px 3px 5px #00000017;
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
}

.features .single-feature i {
  height: 60px;
  width: 60px;
  line-height: 60px;
  text-align: center;
  display: inline-block;
  background-color: $primary;
  color: #fff;
  font-size: 20px;
  border-radius: 7px;
  -webkit-box-shadow: 0px 4px 6px #0000002a;
  box-shadow: 0px 4px 6px #0000002a;
  margin-bottom: 30px;
}

.features .single-feature h3 {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 20px;
}

.features .single-feature p {
  line-height: 22px;
}

/*======================================
    About Us CSS
========================================*/
.about-us {
  background-color: #f9f9f9;
}

.about-us .content-left {
  position: relative;
}

.about-us .content-left img {
  width: 100%;
  border-radius: 5px;
}

.about-us .content-left .video {
  position: absolute;
  height: 80px;
  width: 80px;
  line-height: 80px;
  text-align: center;
  color: $primary;
  background-color: #fff;
  border-radius: 50%;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  font-size: 16px;
  padding-left: 3px;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.about-us .content-left .video:hover {
  color: #fff;
  background-color: $primary;
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}

.about-us .content-left .video:before {
  position: absolute;
  content: '';
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  border: 1px solid #fff;
  border-radius: 50%;
  -webkit-animation: pulse-border-2 2s linear infinite;
  animation: pulse-border-2 2s linear infinite;
}

@-webkit-keyframes pulse-border {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
    opacity: 0;
  }
}

@keyframes pulse-border {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
    opacity: 0;
  }
}

@-webkit-keyframes pulse-border-2 {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 0;
  }
}

@keyframes pulse-border-2 {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 0;
  }
}

.about-us .content-right {
  padding-left: 40px;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
  .about-us .content-right {
    padding: 0;
    margin-top: 30px;
  }
}

.about-us .content-right h2 {
  display: block;
  font-size: 32px;
  margin-bottom: 25px;
  line-height: 45px;
  text-transform: capitalize;
  position: relative;
  font-weight: 700;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-us .content-right h2 {
    font-size: 28px;
    line-height: 38px;
  }
}

@media (max-width: 767px) {
  .about-us .content-right h2 {
    font-size: 22px;
    line-height: 35px;
  }
}

.about-us .content-right h3 {
  font-size: 22px;
  margin-top: 40px;
  margin-bottom: 8px;
  line-height: 45px;
  text-transform: capitalize;
  position: relative;
  font-weight: 600;
}

.about-us .content-right p {
  font-size: 14px;
  line-height: 26px;
}

.about-us .content-right .list {
  margin-top: 50px;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
  .about-us .content-right .list {
    margin-top: 30px;
  }
}

.about-us .content-right .list li {
  display: block;
  margin-bottom: 12px;
  font-size: 14px;
  color: #777;
  padding-left: 25px;
  position: relative;
}

.about-us .content-right .list li i {
  color: $primary;
  font-size: 15px;
  display: inline-block;
  position: absolute;
  left: 0;
  top: 3px;
}

.about-us .content-right .list li:last-child {
  margin: 0;
}

.about-us .content-right .button {
  margin-top: 50px;
}

/*======================================
    Our Achievement CSS
========================================*/
.our-achievement {
  background-color: $primary;
  text-align: center;
  padding: 130px 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .our-achievement {
    padding: 80px 0;
  }
}

@media (max-width: 767px) {
  .our-achievement {
    padding: 60px 0;
  }
}

.our-achievement .title h2 {
  color: #fff;
  font-weight: 700;
  font-size: 35px;
  margin-bottom: 10px;
}

.our-achievement .title p {
  color: #fff;
}

.our-achievement .single-achievement {
  margin-top: 50px;
  text-align: center;
  padding: 0px 10px;
}

.our-achievement .single-achievement h3 {
  font-size: 35px;
  font-weight: 800;
  display: block;
  margin-bottom: 5px;
  color: #fff;
}

.our-achievement .single-achievement p {
  font-size: 15px;
  color: #fff;
  font-weight: 500;
  text-transform: capitalize;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
  .our-achievement .title h2 {
    font-size: 24px;
    line-height: 32px;
  }

  .our-achievement .single-achievement {
    margin-top: 30px;
  }

  .our-achievement .single-achievement h3 {
    font-size: 28px;
  }
}

/*=============================
	Pricing Table CSS
===============================*/
.pricing-table {
  background-color: #f9f9f9;
}

.pricing-table .section-title {
  margin-bottom: 50px;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
(max-width: 767px) {
  .pricing-table .section-title {
    margin-bottom: 30px;
  }
}

.pricing-table .single-table {
  border: 1px solid #eee;
  border-radius: 10px;
  margin-top: 30px;
  background-color: #fff;
  -webkit-transition: all 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55) 0s;
  transition: all 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55) 0s;
}

.pricing-table .single-table:hover:hover {
  -webkit-box-shadow: 0px 3px 5px #00000017;
  box-shadow: 0px 3px 5px #00000017;
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
}

.pricing-table .single-table .table-head {
  padding: 25px;
  border-bottom: 1px solid #eee;
}

.pricing-table .single-table .table-head .title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 20px;
}

.pricing-table .single-table .table-head p {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}

.pricing-table .single-table .table-head .price {
  padding: 30px 0;
}

.pricing-table .single-table .table-head .price .amount {
  font-size: 30px;
  font-weight: 700;
  display: inline-block;
}

.pricing-table .single-table .table-head .price .amount .duration {
  display: inline-block;
  font-size: 14px;
  color: #888;
  font-weight: 400;
  margin-left: 3px;
}

.pricing-table .single-table .table-head .button .btn {
  width: 100%;
  padding: 12px 30px;
  font-size: 13px;
  background-color: #081828;
}

.pricing-table .single-table .table-head .button .btn:hover {
  background-color: $primary;
  color: #fff;
}

.pricing-table .single-table .table-content {
  padding: 25px;
}

.pricing-table .single-table .table-content .middle-title {
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 25px;
}

.pricing-table .single-table .table-content .table-list li {
  position: relative;
  padding-left: 25px;
  font-size: 14px;
  margin-bottom: 13px;
}

.pricing-table .single-table .table-content .table-list li:last-child {
  margin: 0;
}

.pricing-table .single-table .table-content .table-list li i {
  color: $primary;
  font-size: 14px;
  position: absolute;
  left: 0;
  top: 4px;
}

/*======================================
    Call To Action CSS
========================================*/
.call-action {
  padding: 80px 0;
  background-color: #fff;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .call-action {
    padding: 60px 0;
    text-align: center;
  }
}

@media (max-width: 767px) {
  .call-action {
    padding: 50px 0;
    text-align: center;
  }
}

.call-action .text h2 {
  font-size: 32px;
  font-weight: 700;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .call-action .text h2 {
    font-size: 28px;
  }
}

@media (max-width: 767px) {
  .call-action .text h2 {
    font-size: 22px;
  }
}

.call-action .text h2 span {
  display: block;
  color: $primary;
}

.call-action .button {
  float: right;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .call-action .button {
    float: none;
    margin-top: 30px;
  }
}

@media (max-width: 767px) {
  .call-action .button {
    float: none;
    margin-top: 25px;
  }
}

/*======================================
	Carousel CSS
========================================*/
.carousel-indicators [data-bs-target]{
  width: 15px;
  height: 5px;
  border-radius: 5px;
  background-color: var(--light-green);
  opacity: 1;
}
.carousel-indicators .active {
  width: 30px;
}
.carousel-inner {
  height: 30rem;
  .carousel-item {
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
  }
  }
  .carousel-info {
    position: absolute;
    top: 18%;
    font-family: var(--font-source);
    text-align: center;
    width: 100%;
    color: white;

    h6 {
      margin-bottom: 1.5rem;
    }

    h5 {
      font-family: var(--font-bebas);
      font-size: 48px;
      margin: 0 auto 1.5rem;
      font-weight: 500;
      width: 90%;
    }

    p {
      width: 70%;
      margin: 0 auto;
      font-size: 18px;
    }
  }
}

@media screen and (min-width: 475px) {
  .carousel-info {
    top: 29%;
    p {
      width: 50%;
      margin: 0 auto;
      color: white !important;
    }
  }
}

@media screen and (min-width: 320px) and (max-width: 374px) {
  .carousel-info {
    p {
      font-size: 9px;
      line-height: 14px;
      color: white !important;
    }
  }
}

@media screen and (min-width: 375px) and (max-width: 424px) {
  .carousel-info {
    p {
      font-size: 9px;
      line-height: 14px;
      color: white !important;
    }
  }
}

@media screen and (min-width: 425px) and (max-width: 675px) {
  .carousel-info {
    p {
      font-size: 15px;
      line-height: 14px;
      color: white !important;
    }
  }
  .slide-destacado{
    width: 213px !important;
  }
}

@media screen and (min-width: 676px) and (max-width: 767px) {
  .carousel-info {
    p {
      font-size: 9px;
      line-height: 15px;
      color: white !important;
    }
  }
}

@media screen and (min-width: 768px) {
  .carousel-info {
    p {
      font-size: 15px;
      line-height: 23px;
      color: white !important;
    }
  }
}

/*======================================
	End Carousel CSS
========================================*/

/*======================================
	Footer CSS
========================================*/
.footer {
  background-color: var(--dark-green);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  color: white;
  font-family: var(--font-source);

  .footer-top-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .footer-logo {
      display: flex;
      flex-direction: column;
      align-items: center;

      small {
        text-align: center;
        margin: .5rem 0;
        font-size: 15px;
      }
    }

    .redes {
      display: flex;
      width: 100%;
      justify-content: center;
      padding: .5rem;
      margin-bottom: 1rem;

      .icono-redes {
        width: 3rem;
        a{
          display: inline;
        }
      }

      .icono-redes:hover svg circle {
        fill: white
      }

      .icono-redes:hover svg path.cls-2 {
        fill: var(--light-green)
      }
    }
  }

  .newsletter-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid white;
    border-radius: 4px;
    padding: 1rem;

    .data {
      display: flex;
      flex-direction: column;
      align-items: center;

      h3 {
        text-align: center;
        font-size: 1.5rem;
        font-family: var(--font-bebas);
        font-weight: 500;
      }

      p {
        text-align: center;
        margin-bottom: 1rem;
      }
    }

    .newsletter-form {
      form {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      input {
        border: 1px solid white;
        border-radius: 4px;
        color: white;
        background-color: var(--dark-green);
        outline: none;
        padding: .5rem 1rem;
        width: 90%;
        margin-right: 1rem;
      }

      input::placeholder {
        color: white;
      }

      button {
        font-family: var(--font-bebas);
        border: 1px solid white;
        border-radius: 4px;
        color: white;
        background-color: var(--dark-green);
        outline: none;
        padding: .5rem 1rem;
        transition: all 0.3s ease-in-out
      }
      button:hover {
        border: 1px solid white;
        color: var(--ligth-green);
        background-color: white;
      }
    }
  }
}

/* FOOTER MEDIA QUERIES (Mobile First) */

/* Tablet - desktop */

@media screen and (min-width: 768px) {
  .footer {
    padding: 2rem 3rem 4rem;

    .footer-box {
      max-width: 1200px;
    }

    .footer-top-box {
      flex-direction: row;
      padding: 0 0 2rem 0;
      width: 100%;

      .footer-logo {
        flex-direction: row;
        align-items: center;
        width: 50%;

        small {
          text-align: left;
          margin: 0 0 0 2rem;
        }
      }

      .redes {
        display: flex;
        width: 100%;
        justify-content: center;
        padding: .5rem;

        .icono-redes {
          width: 3rem;
          margin-right: .5rem;
        }
      }
    }

    .newsletter-container {
      flex-direction: row;
      justify-content: start;
      align-items: center;
      padding: 1rem;

      .data {
        align-items: start;
        width: 50%;

        h3 {
          text-align: left;
          font-size: 1.5rem;
          font-family: var(--font-bebas);

        }

        p {
          text-align: left;
          margin-bottom: 1rem;
        }
      }

      .newsletter-form {
        width: 50%;
        padding: 0 2rem;

        form {
          gap: 1rem;
        }

        input {
          padding: .5rem 1rem;
          width: 90%;
        }

        button {
          padding: .5rem 1rem;
        }
      }
    }
  }

}


/* .footer {
  background-color: var(--dark-green);
  position: relative;
}

.footer .footer-top {
  padding: 100px 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer .footer-top {
    padding: 80px 0;
    padding-top: 40px;
  }
}

@media (max-width: 767px) {
  .footer .footer-top {
    padding: 60px 0;
    padding-top: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer .single-footer {
    margin-top: 40px;
  }
}

@media (max-width: 767px) {
  .footer .single-footer {
    margin-top: 40px;
    text-align: center;
  }
}

.footer .single-footer.f-about {
  padding-right: 30px;
}

@media (max-width: 767px) {
  .footer .single-footer.f-about {
    padding: 0;
  }
}

.footer .single-footer.f-about .logo img {
  width: 180px;
}

.footer .single-footer.f-about p {
  color: #D2D6DC;
  margin-top: 20px;
  font-size: 14px;
}

.footer .single-footer.f-about .social {
  margin-top: 30px;
}

.footer .single-footer.f-about .social li {
  display: inline-block;
  margin-right: 17px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer .single-footer.f-about .social li {
    margin-bottom: 10px;
  }
}

@media (max-width: 767px) {
  .footer .single-footer.f-about .social li {
    margin-bottom: 10px;
  }
}

.footer .single-footer.f-about .social li:last-child {
  margin: 0;
}

.footer .single-footer.f-about .social li a {
  color: #D2D6DC;
  font-size: 15px;
}

.footer .single-footer.f-about .social li a:hover {
  color: $primary;
}

.footer .single-footer.f-about .social li:last-child {
  margin: 0;
}

.footer .single-footer.f-about .copyright-text {
  color: #D2D6DC;
  font-size: 14px;
  margin-top: 40px;
}

@media (max-width: 767px) {
  .footer .single-footer.f-about .copyright-text {
    margin-top: 20px;
  }
}

.footer .single-footer.f-about .copyright-text a {
  color: #D2D6DC;
}

.footer .single-footer.f-about .copyright-text a:hover {
  color: $primary;
}

.footer .single-footer.f-link li {
  display: block;
  margin-bottom: 12px;
}

.footer .single-footer.f-link li:last-child {
  margin: 0;
}

.footer .single-footer.f-link li a {
  font-size: 15px;
  font-weight: 400;
  color: #D2D6DC;
}

.footer .single-footer.f-link li a:hover {
  color: $primary;
}

.footer .single-footer h3 {
  font-size: 18px;
  font-weight: 600;
  display: block;
  margin-bottom: 35px;
  color: #D2D6DC;
}
 */
/* @media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer .single-footer h3 {
    margin-bottom: 25px;
  }
}

@media (max-width: 767px) {
  .footer .single-footer h3 {
    margin-bottom: 25px;
  }
}

.footer .footer-newsletter {
  padding-bottom: 100px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer .footer-newsletter {
    padding-bottom: 80px;
  }
}

@media (max-width: 767px) {
  .footer .footer-newsletter {
    padding-bottom: 60px;
    text-align: center;
  }
}

.footer .footer-newsletter .inner-content {
  border: 2px solid rgba(238, 238, 238, 0.171);
  padding: 50px;
  border-radius: 8px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer .footer-newsletter .inner-content {
    padding: 30px;
  }
}

@media (max-width: 767px) {
  .footer .footer-newsletter .inner-content {
    padding: 30px;
  }
}

.footer .footer-newsletter .title {
  position: relative;
}

.footer .footer-newsletter .title h3 {
  color: #D2D6DC;
  font-size: 18px;
  display: block;
  margin-bottom: 10px;
  font-weight: 600;
  text-transform: capitalize;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer .footer-newsletter .title h3 {
    font-size: 16px;
  }
}

@media (max-width: 767px) {
  .footer .footer-newsletter .title h3 {
    font-size: 16px;
  }
}

.footer .footer-newsletter .title p {
  font-size: 15px;
  color: #D2D6DC;
}
 */
/* @media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer .footer-newsletter .title p {
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  .footer .footer-newsletter .title p {
    font-size: 14px;
  }
}

.footer .footer-newsletter .title p a {
  color: $primary;
  text-decoration: underline;
}

.footer .footer-newsletter .title p a:hover {
  color: #fff;
}

.footer .footer-newsletter .newsletter-form {
  position: relative;
}

@media (max-width: 767px) {
  .footer .footer-newsletter .newsletter-form {
    margin-top: 30px;
  }
}

.footer .footer-newsletter .newsletter-form input {
  height: 48px;
  width: 300px;
  display: inline-block;
  background: transparent;
  border: none;
  color: #fff;
  border-radius: 0;
  padding: 0 20px;
  color: #fff !important;
  font-size: 14px;
  background-color: #fff3;
  border-radius: 5px;
  float: right;
  margin-right: 148px;
}
 */

/* @media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer .footer-newsletter .newsletter-form input {
    width: 100%;
    margin: 0;
    padding-right: 144px;
  }
}

@media (max-width: 767px) {
  .footer .footer-newsletter .newsletter-form input {
    width: 100%;
    margin: 0;
    text-align: center;
  }
} */

.footer .footer-newsletter .newsletter-form ::-webkit-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #fff;
  opacity: 1;
  /* Firefox */
}

.footer .footer-newsletter .newsletter-form :-ms-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #fff;
  opacity: 1;
  /* Firefox */
}

.footer .footer-newsletter .newsletter-form ::-ms-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #fff;
  opacity: 1;
  /* Firefox */
}

.footer .footer-newsletter .newsletter-form ::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #fff;
  opacity: 1;
  /* Firefox */
}

.footer .footer-newsletter .newsletter-form :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #fff;
}

.footer .footer-newsletter .newsletter-form ::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #fff;
}

.footer .footer-newsletter .newsletter-form .button {
  display: inline-block;
  position: absolute;
  right: 0;
}

.logo-text {
  font-size: 1.1rem;
}


/* @media (max-width: 767px) {
  .footer .footer-newsletter .newsletter-form .button {
    position: relative;
    margin-top: 10px;
    left: 0;
    bottom: 0;
    width: 100%;
  }
  .footer .footer-newsletter .newsletter-form .button .btn {
    width: 100%;
  }
}

.footer .footer-newsletter .newsletter-form .button .btn {
  display: inline-block;
  background: $primary;
  color: #fff;
  height: 48px;
  line-height: 48px;
  padding: 0 30px;
}

.footer .footer-newsletter .newsletter-form .button .btn::before {
  background-color: #fff;
}

.footer .footer-newsletter .newsletter-form .button .btn:hover {
  color: $primary;
  background-color: #fff;
}

.contact-bg {
  background-image: url("../../img/contact.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  z-index: 2;
}

.contact-bg::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 100%;
  width: 60%;
  opacity: 0.9;
  background-color: $secondary;
  z-index: -1;
}
 */

@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Source+Sans+Pro:wght@400;600;700;900&display=swap");

:root {
  --font-bebas: "Bebas Neue", cursive;
  --font-source: "Source Sans Pro", sans-serif;
  --font-camper-quotes: "Camper Quotes", sans-serif;
  --dark-green: #01631a;
  --light-green: #46c54e;
  --dark-red: #7a3429;
  --light-red: #ca5d5a;
  --orange: #FF6C08;
  --light-grey: #eeefef;
  --medium-grey: #c4c4c4;
  --grey: #6d6e71;
  --dark-grey: #3e3e3e;
  --mb-mobile: 25px;
}

.text-custom-darkred {
  color: var(--dark-red) !important;
}

#app {
  padding: 2rem 0 5rem;
  background-image: url(https://elclick.com.ar/build/images/guarda-ilus-home.8768f753.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-color: #ffd189;
  background-position: center;
}

@media screen and (min-width: 475px) {
  #app {
    padding: 5rem 0;
  }
}

.form {
  width: 90%;
  margin: 0 auto;
  max-width: 37.5rem;
  background-color: white;
  border-radius: 0.56rem;
  padding: 22px 17px;
}

.form-stepper {
  padding: 0 !important;
  margin: var(--mb-mobile) 0 18px;
}

.form-stepper svg {
  color: #c4c4c4;
}

.btn-container {
  display: flex;
  justify-content: end;
  position: relative;
}

.form-button {
  background-color: var(--light-green);
  color: white;
  border: none;
  border-radius: 5px;
  padding: 1rem 1.5rem;
  font-family: var(--font-bebas);
  font-size: 21px;
  transition: all 0.3s ease-in;
}

.form-button:hover {
  background-color: #01631a;
  color: white;
}

.back-button {
  color: var(--dark-red);
  background-color: white;
  border: none;
  border-radius: 5px;
  padding: 1rem 1.5rem;
  font-family: var(--font-bebas);
  font-size: 21px;
}

.instructions {
  margin: 1rem 0;
}

.title {
  text-align: center;
  font-family: var(--font-camper-quotes);
  font-size: 3.3rem;
  line-height: 3.1rem;
  font-weight: 400;
  margin: 0 auto;
  color: var(--dark-red);
}

.subtitle__min_amount {
  font-size: 1rem;
  text-align: center;
  font-family: var(--font-source);
  margin: 0 auto;
  color: var(--light-green);
}

.form-subtitle {
  font-family: var(--font-source);
  font-weight: 600;
  font-size: 21px;
  color: var(--dark-red);
  margin-bottom: 14px;
}

.form input {
  font-family: var(--font-source);
  font-weight: 400;
  font-size: 1.16rem;
  color: var(--grey);
  border: none;
  border-radius: 0;
}

.form input::placeholder {
  font-family: var(--font-source);
  font-weight: 400;
  font-size: 1.16rem;
  color: var(--grey);
}

svg.MuiStepIcon-completed,
span.MuiRadio-colorSecondary.Mui-checked {
  fill: var(--light-green) !important;
}

svg.MuiStepIcon-active {
  fill: var(--light-red) !important;
}

.MuiRadio-colorSecondary.Mui-checked {
  fill: var(--light-green) !important;
  color: var(--light-green) !important;
}

.MuiStep-horizontal {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.MuiStepIcon-text {
  font-family: var(--font-source) !important;
  font-size: 13px !important;
  font-weight: 400 !important;
}

.MuiTypography-body2 {
  font-size: 14px !important;
}

.MuiFormControl-root {
  width: 100%;
}

/* Estilos de confirmacion de pago */
.info-item {
  display: flex;
  margin-bottom: var(--mb-mobile);
}

.info-item:nth-child(1) {
  width: 10%;
}

.info-item p {
  padding-left: 1rem;
}

.status-title {
  font-family: var(--font-source);
  font-weight: 600;
  background-color: var(--light-green);
  margin-bottom: 14px;
  border-radius: 5px;
  padding: 10px 0;
  color: white;
  text-align: center;
}

.status-title p {
  font-size: 1.31rem;
}

.form-subtitle-status {
  font-family: var(--font-source);
  font-weight: 600;
  font-size: 1.31rem;
  color: var(--dark-red);
  margin: 14px 0;
}

.status-total {
  font-family: var(--font-source);
  font-weight: 600;
  font-size: 1.31rem;
  background-color: var(--light-green);
  margin-bottom: 1.56rem;
  border-radius: 5px;
  padding: 10px 1rem;
  color: white;
  display: flex;
  justify-content: space-between;
}

.status-total-1 {
  width: 50%;
}

.mp-confirm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.pay-title {
  font-family: var(--font-source);
  font-weight: 600;
  font-size: 21px;
  background-color: var(--grey);
  margin-bottom: 24px;
  border-radius: 5px;
  padding: 10px 1rem;
  color: white;
  text-align: center;
  width: 100%;
  height: 67px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pay-text {
  font-family: var(--font-source);
  font-size: 18px;
  color: #6d6e71;
  margin-bottom: 24px;
}

.pay-button {
  width: 159px;
  height: 48px;
  border-radius: 4px;
  background-color: var(--light-green);
  font-family: var(--font-bebas);
  font-weight: 400;
  font-size: 21px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
}

.pay-button:hover {
  background-color: #01631a;
  color: white;
}

.messageButton {
  padding: 0.5rem;
  background-color: #e65117;
  color: white;
  text-align: center;
  border-radius: 5px;
  position: absolute;

  font-family: var(--font-source);
  font-style: italic;
  font-weight: bold;
  font-size: 14px;
  top: 5rem;
  width: 100%;
}

.text-red {
  color: var(--dark-red);
}

@media screen and (min-width: 475px) {
  .messageButton {
    top: 5rem;
  }
}
@media screen and (min-width: 625px) {
  .title {
    font-size: 4rem;
    line-height: 3.8rem;
  }
  .form {
    padding: 22px 33px;
  }
  
}

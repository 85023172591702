
.confirmation-title {
  font-family: var(--font-source);
  font-weight: 600;
  font-size: 1.31rem;
  background-color: var(--light-red);
  margin-bottom: 1.56rem;
  border-radius: 5px;
  padding: 10px 1rem;
  color: white;
  text-align: center;
}

.confirmation-total {
  font-family: var(--font-source);
  font-weight: 600;
  font-size: 1.31rem;
  background-color: var(--grey);
  margin-bottom: 1.56rem;
  border-radius: 5px;
  padding: 10px 1rem;
  color: white;
  text-align: center;
}

.form-subtitle-confirmation {
  font-family: var(--font-source);
  font-weight: 600;
  font-size: 21px;
  color: var(--dark-red);
  margin: 14px 0;
}


.total-box {
  display: flex;
  flex-direction: column;
  padding: 10px 25px;
  margin-top: 25px;
}

.total {
  display: flex;
  justify-content: space-between;
}


.confirmation .total-box p{
  font-family: var(--font-source);
  font-size: 16px;
  width: 100%;
  text-align: start;
  font-style: italic;
}

.confirmation p {
  font-family: var(--font-source);
  font-size: 18px;
  line-height: 23px;
}

.item-prices {
  font-family: var(--font-source);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.deliveryCost {
  /* border-bottom: 1px solid var(--grey); */
}

@media (min-width: 992px){
  .item-prices span{
    font-size: 18px;
  }
}

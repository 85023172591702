.form.promotions-current-date {
  width: 100%;
  margin-top: 20px;
  padding: 20px;
  border-radius: 20px;
}

.form.promotions-current-date.active-border {
  border: 1px solid var(--dark-green);
}

.form.promotions-current-date .day-title {
  font-family: var(--font-bebas);
  color: var(--dark-green);
  font-size: 1.5rem;
  font-weight: 100;
  margin-bottom: 1.5rem;
}

.form.promotions-current-date .pay-method {
  margin: 8px 0
}

.form.promotions-current-date .promotions-list {
  font-family: var(--font-source);
  font-size: 14px;
  line-height: 23px;
  display: flex;
  flex-direction: column;
}

.form.promotions-current-date .icon-promotion {
  width: 35px !important;
  height: 35px !important;
}

/* ACCORDION PROMOTIONS POINTS */
.form .accordion-promotions-points .accordion-button.collapsed {
  border-radius: 20px !important;
  background-color: white !important;
  color: var(--gray) !important;
}

.form .accordion-promotions-points .accordion-button {
  color: var(--dark-red) !important;
  background-color: white !important;
}

.form .accordion-promotions-points .accordion-button.inactive {
  color: var(--gray) !important;
}

.form .accordion-promotions-points .accordion-button:not(.collapsed)::after {
  filter: hue-rotate(280deg) !important;
}

.form .accordion-promotions-points .accordion-button:not(.collapsed) {
  box-shadow: none;
}

.form .accordion-promotions-points .accordion-item {
  border-radius: 20px !important;
  overflow: hidden;
}

.form .accordion-promotions-points .accordion-item.active-border {
  border: 1px solid var(--dark-green) !important;
}
.points-section {
  padding-bottom: 16px;
}

.points-section label span {
  font-weight: 400;
}
.points-section select {
  border: none;
  border-radius: 0;
  border-bottom: 1px solid var(--grey);
  margin-bottom: 1.56rem;
  padding: 0;
  font-family: var(--font-source);
}

.points-section select:focus {
  outline: none;
  box-shadow: none;
}
.points-section .modality-info {
  margin-left: 1.9rem;
  font-weight: 400;
  font-size: 13px;
}

.points-section .point__name {
  color: #01631a;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 14px;
  position: relative;
  bottom: -1px;
}
.points-section .point__name-disabled {
  color: var(--grey);
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 14px;
  position: relative;
  bottom: -1px;
}

.points-section .point__new {
  color: #FF6C08;
  font-family: 'Source Sans Pro';
  text-transform: uppercase;
  font-weight: 600;
  font-size: 13px;
  line-height: 14px;
  position: relative;
  bottom: -1px;
}

.points-section .address-span {
  font-size: 15px;
  position: relative;
  bottom: -1px;
}

.points-section .MuiFormControlLabel-root {
  margin-right: 0 !important;
  height: 1.6rem;
}

.form-control_select {
  width: 100% !important;
  margin-bottom: 1.56rem !important;
}

.MuiFormLabel-root.Mui-focused {
  color: var(--light-green) !important;
}

.MuiInput-underline::after {
  border-bottom: 2px solid var(--light-green) !important;
}

.item-day {
  text-transform: capitalize;
}

.points-section .MuiSelect-select.MuiSelect-select {
  text-transform: capitalize;
}

.point-observation {
  font-weight: bold;
}


@media screen and (min-width: 475px) {
  .points-section label span {
    font-size: 17px;
  }
  .points-section select {
    width: 50%;
  }
  .points-section .address-span {
    margin-top: 0;
    font-size: 15px;
  }

  .points-section .MuiFormControlLabel-root {
    margin-right: 0 !important;
    height: 2rem;
  }  

  .points-section .modality-info {
    font-size: 17px;
    line-height: 17px;
  }

  .points-section .point__new {
    font-size: 17px;
    line-height: 17px;
  }
  
  .points-section .point__sale {
    font-size: 17px;
    line-height: 17px;
  }

}


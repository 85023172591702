.modal__container{
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal__Body {
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    border: none;
    outline: none;
    padding: 1rem 2rem;
    width: 325px;
    text-align: center;
    border-radius: 10px;
}

.modal__Body i {
    position: absolute;
    right: 1rem;
    top: 9px;
    font-size: 23px;
    color: lightgray;
    cursor: pointer;
}

.modal__Body-question {
    font-family: var(--font-source);
    font-weight: 600;
    font-size: 21px;
    color: var(--dark-red);
    margin: 15px 0 8px;
    width: 85%;
}

.modal__Body-paragraph {
    font-family: var(--font-source);
    font-weight: 400;
    font-size: 1.16rem;
    color: var(--grey);
    margin-bottom: 15PX;
    width: 85%;
}

.modal__Body-buttonsContainer {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
}

.modal__button {
   /*  background-color: var(--light-green);
    color: white; */
    border: none;
    border-radius: 5px;
    padding: 0.7rem 0.8rem;
    font-family: var(--font-bebas);
    font-size: 14px;
    transition: all 0.3s ease-in;
}
.modal__button.new{
    color: var(--dark-red);
    border: 1px solid var(--dark-red);
    background-color: white;
}
.modal__button.next {
    background-color: var(--light-green);
    border: 1px solid var(--light-green);
    color: white;
}
.delivery {
  padding: 0 0 15px 0 !important;
}

.delivery-title {
  font-family: var(--font-source);
  font-weight: 600;
  font-size: 13px;
  line-height: 26px;
  color: white;
  background-color: var(--grey);
  padding: 1rem 1rem;
  border-radius: 5px;
  margin-bottom: 15px;
  list-style: disc;
}

.delivery-title li {
  display: list-item;
  margin-left: 1rem;
}

.delivery input,
.delivery select {
  font-family: var(--font-source);
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: var(--grey);
}

.input-group-mui {
  margin-bottom: 1.56rem;
}

.code-info {
  height: 100%;
  display: flex;
  align-items: end;
  padding: 0.5rem 0;
  position: absolute;
  top: 0;
  right: 0;
}

.delivery select {
  border: none;
  border-bottom: 1px solid var(--grey);
  padding: 0;
  border-radius: 0;
}

.delivery select:focus {
  outline: none;
  box-shadow: none;
}

.delivery input::placeholder,
.delivery select::placeholder,
.delivery textarea::placeholder {
  font-family: var(--font-source);
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: var(--grey);
}

.delivery textarea {
  resize: none;
  width: 100%;
  height: 8rem;
}

.delivery-postalCode {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  line-height: 1.5;
  background-clip: padding-box;
  appearance: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-bottom: var(--mb-mobile);
  height: fit-content;
  position: relative;
  width: 100%;
}

.delivery-postalCode input {
  border: none;
  width: 50%;
  margin-bottom: 0;
}

.delivery-postalCode span a {
  color: var(--light-green);
  font-size: 0.7rem;
}

.delivery-subtitle {
  font-family: var(--font-source);
  font-weight: 600;
  font-size: 21px;
  color: var(--dark-red);
}

.error-pc {
  position: relative;
  top: -15px;
}

.form-control_delivery-select {
  width: 100% !important;
  margin-bottom: 1.56rem !important;
  position: relative;
  bottom: -2px;
}

.form-control_delivery-select label:focus {
  color: var(--light-green) !important;
}

.delivery-postalCode_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
}

@media screen and (min-width: 768px) {
  .form-control_delivery-select {
    margin-bottom: 0rem !important;
  }
}

@media screen and (min-width: 992px) {
  .delivery-title {
    padding: 0.9rem 1rem;
    margin-bottom: 25px;
    font-size: 14px;
  }
}

/* Title */
.orderSectionTitle {
  color: var(--light-green);
  font-family: var(--font-source);
  font-size: 14px;
  margin-bottom: 7px;
  font-weight: bold;
}

.orderWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* padding-bottom: 2rem; */
}

.orderBodyContainer {
  width: 100%;
}

.productsSectionContainer {
    padding: 1.2rem 0 0;

}

/* Product Card */

.producCardContainer {
  border-bottom: 1px solid #e6e6e6;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.productCardItem {
  display: flex;
  justify-content: space-between;
  padding: 11px 0 11px;
  width: 100%;
}

.emptyBagItemContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.emptyBagItemContainer p{
  font-size: 16px;
  font-family: var(--font-source);
  line-height: 16px;
}

.productCardImage {
  min-width: 78px;
  width: 78px;
  height: 78px;
}

.productCardImage img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.product-quantity {
  font-family: var(--font-source);
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  color: var(--grey);
}

.productCardData {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 13px;
}
.productDataContainer {
  display: flex;
  align-self: flex-start;
  flex-direction: column;
}

.productData {
  font-family: var(--font-source);
  font-weight: 600;
  font-size: 16px;
  line-height: 14px;
  color: var(--grey);
  margin-bottom: 0;
}
.comboDescription {
    width: 90%;
    line-height: 10px;
}
.comboDescription p {
  font-family: var(--font-source);
  color: var(--grey);
  font-size: 11px;
  line-height: 9px;
  display: inline;
}

.productCardData span.varieties {
  font-size: 16px;
  font-family: var(--font-source);
}

.product__sale_desktop {
  color: var(--dark-red) !important;
  font-family: "Source Sans Pro";
  text-transform: uppercase;
  font-size: 13px;
  line-height: 14px;
  position: relative;
  bottom: -1px;
  display: none;
}

.product__sale_mobile {
  font-size: 12px;
  line-height: 12px;
  color: white !important;
  font-family: "Source Sans Pro";
  text-transform: uppercase;
  position: relative;
  bottom: -1px;
  background-color: var(--orange);
  border-radius: 7px;
  padding: 0 3px;
}

.product-buttons-group {
  display: flex;
  justify-content: space-evenly;
  width: 5rem;
  min-width: 5rem;
  align-items: center;
  flex-grow: 20%;
}

.product-bag_button {
  width: 1.3rem;
  height: 1.3rem;
  border-radius: 50%;
  border: none;
  background-color: var(--medium-grey);
  font-family: var(--font-source);
  font-weight: 600;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  user-select: none;
  cursor: pointer;
}

.product-bag_button.active-button {
  background-color: var(--light-green);
}

/* .tabsContainer {
  padding: 8px 0 22px;
} */
@media screen and (min-width: 475px) {
  .productCardData {
    flex-direction: column;
    padding: 0 1rem;
  }

  .orderSectionTitle {
    font-size: 16px;
    margin-bottom: 11px;
  }

  .productCardData span.varieties {
    margin-left: 0.5rem;
    font-size: 18px;
    font-weight: 400;
  }

  .producCardContainer {
    display: flex;
    flex-direction: column;
  }
  
  .productCardItem {
    padding: 8px 0 8px;
  }

  .productCardImage {
    /* border: 1px solid black; */
    min-width: 85px;
    width: 85px;
    height: 85px;
    display: block;
  }

  .productData {
    font-size: 16px;
    line-height: 27px;
    margin-bottom: 6px;
  }
 
  .productData .productName {
    font-weight: bold;
  }

  .comboDescription p {
    font-size: 12px;
    line-height: 12px;
  }
  
  .product-bag_button {
    width: 1.5rem;
    height: 1.5rem;
  }

  .productCardData span.varieties {
    font-size: 18px;
  }
}

@media screen and (min-width: 475px) {
  .product__sale_desktop {
    font-size: 17px;
    line-height: 17px;
    display: inline-block;
    color: white !important;
    font-family: "Source Sans Pro";
    text-transform: uppercase;
    position: relative;
    bottom: -1px;
    background-color: var(--orange);
    border-radius: 5px;
    padding: 0 3px;
  }

  .product__sale_mobile {
    display: none;
  }

  .productDataContainer {
    flex-direction: row;
  }

}

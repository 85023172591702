.userData {
  padding: 0 !important;
}
.modality-box {
  border: 1px solid var(--grey);
  padding: 7px 16px 14px;
  border-radius: 5px;
  margin-bottom: 16px;
  user-select: none;
}
.active-box {
  border: 1px solid var(--light-green);
}
.active-box-label {
  color: var(--light-green);
}
.label > span {
  font-family: var(--font-bebas) !important;
  font-size: 21px;
  line-height: 24px;
}

.radio-container {
  width: 100%;
}

.modality-info {
  font-family: var(--font-source);
  font-size: 0.87rem;
  font-weight: 400;
  color: var(--grey);
  display: block;
}

.modify-data {
  border: none;
  padding: 0.5rem;
  width: 50% !important;
  margin: 0 auto;
  border-radius: 0.5rem;
  background-color: var(--light-green);
  color: white;
  font-family: var(--font-source);
  margin-bottom: var(--mb-mobile);
}

.disabled-box {
  border: 1px solid var(--dark-grey);
  min-height: 85px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: var(--dark-grey);
}

.disabled-label > span{
  color: var(--dark-grey) !important;
}

.modalitySkeleton {
  border: 1px solid var(--dark-grey);
  min-height: 85px;
  border-radius: 5px;
  margin-bottom: 16px;
  user-select: none;
  background-color: #f0f0f0;
  width: 100%;
  animation: loading 1.5s ease infinite; 
}

@keyframes loading {
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0.2;
  }
}
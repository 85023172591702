.bags-selection {
  display: flex;
  margin: var(--mb-mobile) 0;
}

.bag-button {
  border-radius: 50%;
  height: 2.37rem;
  width: 2.37rem;
  color: var(--grey);
  border: 1px solid var(--grey);
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--font-source);
  font-size: 1.12rem;
  font-weight: 400;
  cursor: pointer;
  user-select: none;
  margin-right: 1rem;
}

.active-bag-button {
  color: white;
  background-color: var(--light-green);
  border: 1px solid var(--light-green);
}

.form-subtitle_order {
  font-family: var(--font-source);
  font-weight: 600;
  font-size: 21px;
  color: var(--dark-red);
  margin-bottom: 0;
}

.product-bag_name {
  font-family: var(--font-source);
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  color: var(--grey);
  margin-bottom: 0;
}

.product-bag_button {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  border: none;
  background-color: var(--medium-grey);
  font-family: var(--font-source);
  font-weight: 600;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  user-select: none;
  cursor: pointer;
}

.product-bag_button.active-button {
  background-color: var(--light-green);
}

.bag_item {
  border-bottom: 0.5px solid #c4c4c4;
  display: flex;
  justify-content: space-between;
  padding: 0 0 0.5rem;
}

.product-buttons-group {
  display: flex;
  justify-content: space-evenly;
  width: 5rem;
  min-width: 5rem;
  align-items: center;
}

.product-quantity {
  font-family: var(--font-source);
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  color: var(--grey);
}

.smallBags-selection {
  margin: 14px 0;
}

.bag-total {
  text-align: right;
  margin: 10px 0;
}

.other-radio {
  display: flex;
  width: 50%;
  border-bottom: 1px solid var(--grey);
}

.other-radio input {
  border: none;
}

.tip-paragraph {
  font-family: var(--font-source);
  font-size: 18px;
  line-height: 23px;
  color: var(--grey);
  margin: 14px 0;
}

.radio-container_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#radio-2 {
  width: 50%;
  margin: 0;
}

.order-section {
  padding: 0;
}

.product-bag_item {
  display: flex;
  flex-direction: column;
}

.checkbox-box {
  width: 100%;
}

.price {
  font-weight: 700;
}

.product-bag_item span.varieties {
  font-size: 18px;
  font-family: var(--font-source);
}

.empty {
  border-bottom: none;
  /* margin-top: 14px; */
}

.emptyBagQuestion {
  font-size: 16px;
  font-family: var(--font-source);
  font-weight: 600;
  color: var(--dark-red);
  margin-bottom: 0;
  width: 80%;
}

.discountField {
  padding: 14px 0;
}

.order__info {
  padding: 10px 25px 15px;
  border-radius: 5px;
  background-color: var(--light-green);
  color: white;
  margin-bottom: 25px;
}

.order__info--title {
  font-weight: 600;
  text-align: center;
}

.order__info--boldText {
  font-weight: 600;
}

.total_box-container {
  position: sticky;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
}

.total_box {
  background-color: white;
  padding: 0rem 1rem;
  border-radius: 5px;
  border: 2px solid var(--dark-red);
  margin-bottom: 10px;
}

.order__bagInactive {
  border: 1px solid var(--light-green);
  padding: 1rem 2rem;
  border-radius: 5px;
  margin-bottom: 25px;
}

.order__bagInactive {
  color: var(--dark-red);
  font-weight: 600;
}

.order__bagInactive span.bolsonesAgotados{
  color: var(--light-green);
  font-weight: 600;
}

.bagDescriptionContainer {
  padding-top: 1rem;
}

.selectContainer { 
  padding: 1rem 0 2rem;
}

.MuiTab-root {
  min-width: 52px !important;
  width: 52px !important;
  font-family: var(--font-bebas) !important;
  font-size: 0.8rem !important;
  padding: 5px 0 !important;
}

.MuiTabs-indicator {
  background-color: var(--light-green) !important;
  height: 4px !important;
}


.coupon-success {
  color: var(--light-green);
}

@media screen and (min-width: 475px) {

  .MuiTab-root {
    min-width: 62px !important;
    width: 62px !important;
    font-size: 1rem !important;
  }
  

  .product-bag_item {
    flex-direction: row;
    width: 75%;
  }

  .product-bag_item span {
    margin-left: 0.5rem;
  }

  .emptyBagQuestion {
    margin-bottom: 0.5rem;
  }

}

.product__sale {
  color: var(--dark-red) !important;
  font-family: 'Source Sans Pro';
  text-transform: uppercase;
  font-weight: 600;
  font-size: 13px;
  line-height: 14px;
  position: relative;
  bottom: -1px;
}


@media screen and (min-width: 475px) {
  
.product__sale {
    font-size: 17px;
    line-height: 17px;
  }

}

@media screen and (min-width: 768px) {
  
  .selectContainer { 
    width: 50%;
  }

  .MuiTab-root {
    min-width: 100px !important;
    width: 108px !important;
    font-size: 22px !important;
    letter-spacing: 0 !important;
  }

  .MuiTab-textColorInherit {
    opacity: 0.3 !important;
  }

  .MuiTab-textColorInherit.Mui-selected {
    opacity: 1 !important;
  }

}


.fixed-total {
  position: fixed;
  bottom: 0;
  z-index: 1000;
}